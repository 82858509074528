import React from 'react'
import "./orgAfterSignUp.css";
import { useNavigate } from 'react-router-dom';

const OrgAfterSignUp = () => {
  const navigate = useNavigate();
  return (
    <div className='orgAfterSignUp'>
        <img className="logo" src="/logo109.png"></img>
        <div className='info'>
            <h2>Hello!</h2>
            <p>You are just one step away from using Roomie, we have sent you an email. Please verify your account.</p>
        </div>
        <button onClick={()=>{ navigate('/') } }>Go to login</button>
    </div>
  )
}

export default OrgAfterSignUp;
