import { useSelector } from "react-redux";
import { selectExternalUrlState } from "./externalNavigationSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export async function useExternalNavigation() {
  console.log("useExternalNavigation");
  const { url } = useSelector(selectExternalUrlState);
  let navigate = useNavigate();

  useEffect(() => {
    if(!url) return
    navigate(url);
  }, [url]);
}
