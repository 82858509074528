export async function getUsers(page, q) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `/api/users?page=${page}`;
  if (q) url = `${url}&q=${q}`;

  const response = await fetch(url, {
    method: "get",
    headers,
  });
  if (response.status > 400) {
    throw response;
  }
  const res = await response.json();
  return res.Data;
}

export async function inviteUser(email) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `/api/users/invite`;

  const response = await fetch(url, {
    method: "post",
    headers,
    body: JSON.stringify({ email }),
  });
  if (response.status > 400) {
    throw response;
  }
  const res = await response.json();
  return res.Data;
}

export async function changePwd(email) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `/api/users/forgotPwd`;

  const response = await fetch(url, {
    method: "post",
    headers,
    body: JSON.stringify({ email }),
  });
  if (response.status > 400) {
    throw response;
  }
  const res = await response.json();
  return res.Data;
}

export async function changeForgotPassword({ password, validateToken }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `/api/changeForgotPassword`;

  const response = await fetch(url, {
    method: "post",
    headers,
    body: JSON.stringify({ password, validateToken }),
  });
  
  const res = await response.json()
  if (response.status > 400) {
    throw res;
  }
  return res.Data;
}

export async function getUploadUrl({ filename, mimeType }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`/api/file/getUploadUrl`, {
    method: "post",
    headers,
    credentials: "include",
    body: JSON.stringify({ filename, mimeType }),
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function updateUserProfile({ name, avatar, phoneNo, address, position, isVerified }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`/api/users/profile`, {
    method: "post",
    headers,
    credentials: "include",
    body: JSON.stringify({ name, avatar, phoneNo, address, position, isVerified }),
  });

  if (response.status > 400) {
    throw response;
  }
  const res = await response.json();
  return res.Data;
}

export async function updateOrganization({ totalBranch, address, totalEmployee, orgAvatar, phoneNo, description, isVerified }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`/api/orgProfile`, {
    method: "post",
    headers,
    credentials: "include",
    body: JSON.stringify({ totalBranch, address, totalEmployee, orgAvatar, phoneNo, description, isVerified }),
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function changePassword({ pwd }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`/api/users/changePwd`, {
    method: "post",
    headers,
    credentials: "include",
    body: JSON.stringify({ pwd }),
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function forgotPwd(email) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`/api/users/forgotPwd`, {
    method: "post",
    headers,
    credentials: "include",
    body: JSON.stringify({ email }),
  });


  if (response.status > 400) {
    throw response;
  }
  const res = await response.json();
  return res.Data;
}

export async function inviteOrg(adminEmail, adminName, fullOrgName) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `/api/organizations/inviteOrg`;
  const response = await fetch(url, {
    method: "post",
    headers,
    body: JSON.stringify({ adminEmail, adminName, fullOrgName }),
  });
  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}
