import { Avatar } from "antd";
import { useEffect, useState } from "react"
import { getOrganizations } from "../../api/login";
import { createOAuthLink, useOAuth } from "../../api/useOAuth";
import "./oAuthSelectWorkspace.css";

const WorkspaceBox = ({organization , onClick}) => {
    return (
        <div key={organization.uid} className="workspaceBox" onClick={onClick}>
            <Avatar size={45} src={`${organization.pic}/l`} shape="circle" />
            <div className="title">
                <p>{organization.name}</p>
                <h2>{organization.fullOrgName || organization.name}</h2>
            </div>
            <div className="description">
                {organization.description}
            </div>
        </div>
    )
}

export function OAuthSelectWorkspace() {
    const [isLoading, setIsLoading] = useState(false)
    const [organizations, setOrganization] = useState([])

    const { redirectUri, clientID, state, nonce, authType, orgnames } = useOAuth();

    const fetchOrganizationDetails = async () => {
        const organizations = await getOrganizations(orgnames)
        setOrganization(organizations)
    }

    const handleOrgSelect = async (orgname) => {
        setIsLoading(true)
        const url = createOAuthLink(`/api/oauthSelectWorkspace`, { redirectUri, clientID, state, nonce, authType, orgname });
        window.location.href = url
        setIsLoading(false)
    }

    useEffect(() => {
        if (!orgnames || !orgnames.length) return
        fetchOrganizationDetails()
    }, [orgnames])



    if (isLoading || organizations.length == 0) {
        return <div>Loading....</div>
    }

    return (
        <div className="oAuthSelectWorkspace">
            <img className="logo" src="/logo109.png" />
            <h2>Choose a Workspace</h2>
            <div className="workspaceOption">
                {organizations.map(organization => {
                    return <WorkspaceBox organization={organization} onClick={()=>handleOrgSelect(organization.name)}></WorkspaceBox>
                })}
            </div>
        </div>
    )
}
