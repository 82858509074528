import { message } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import "./invite.css";
import { useNavigate } from "react-router-dom";
import Search from "antd/lib/input/Search";
import { inviteUser } from "../../api/users";
import { useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";


function validateEmail(email) {
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

export function Invite() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onInvite = async (email) => {
    try {
      setIsLoading(true);
      if (!validateEmail(email)) {
        message.error("Please enter valid email");
        setIsLoading(false);
        return;
      }

      await inviteUser(email);
      message.success("Invite sent successfully");
      navigate("/users");
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div className="Invite">
      <div className="header">
        <PageHeader
          onBack={() => navigate(-1)}
          title="Invite User"
          subTitle="Invite team mates to your workspace"
        />
      </div>

      <Search
        className="search"
        placeholder="Email"
        enterButton="Invite"
        size="large"
        type="email"
        onSearch={onInvite}
        loading={isLoading}
        prefix={<UserAddOutlined />}
      />
    </div>
  );
}
