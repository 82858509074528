import { Input, message, Space, Typography, Upload } from "antd";
import validator from "validator";
import "./override.css";
import { ProfileUpload } from "../profile/profile";
import { useEffect, useState } from "react";
import { updateOrganization } from "../../api/users";
import { useDashboard } from "../../api/useDashboard";
import "./orgOnboarding.css";
import { changePassword, updateUserProfile } from "../../api/users";
import { useNavigate } from "react-router-dom";

export function OrgOnboarding() {
  const [current, setCurrent] = useState(0);
  const [address, setAddress] = useState("");
  const [totalBranch, setTotalBranch] = useState("");
  const [totalEmployee, setTotalEmployee] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [orgAvatar, setOrgAvatar] = useState(null);
  const navigate = useNavigate();

  async function onComplete() {
    await updateUserProfile({ isVerified: true });
    await updateOrganization({ isVerified: true });
    navigate("/login");
  }

  async function onNext() {
    switch (current) {
      case 0:
        if (!password || !password.trim().length) {
          message.warning("Please enter a valid password");
          return;
        }
        if (password.length < 8) {
          message.warning("Minimum 8 letter password is required");
          return;
        }
        if (password !== confirmPassword) {
          message.warning("Password does not match with confirm password");
          return;
        }
        await updateUserProfile({ avatar });
        await changePassword({ pwd: password });
        break;
      case 1:
        if (
          !phoneNo ||
          !phoneNo.trim().length ||
          !validator.isMobilePhone(phoneNo)
        ) {
          message.warning("Please enter a valid phone number");
          return;
        }
        if (
          !totalEmployee ||
          !totalEmployee.trim().length ||
          !validator.isInt(totalEmployee)
        ) {
          message.warning("Please enter a valid employee number");
          return;
        }
        if (!address || !address.trim().length) {
          message.warning("Please enter a valid organization address");
          return;
        }
        updateOrganization({
          address,
          totalEmployee,
          orgAvatar,
          phoneNo,
        });
        break;
      default:
        break;
    }
    setCurrent(current + 1);
  }

  const ProfileScreen = () => (
    <div className="profile-screen">
     <div className="img-section">
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <img src="/profile-setup.svg" />
        </Space>
      </div>
      <div className="input-section">
        <Input.Group size="large" className="input-group">
          <Typography.Title level={2}>Setup Admin Profile</Typography.Title>
          <div className="pic-upload">
            <div className="image-upload">
              <div className="profile-view">
                <ProfileUpload
                  className="avatar-section"
                  onAvatarChange={(avatar) => {
                    setAvatar(avatar);
                  }}
                  avatar={avatar}
                  type={"photo"}
                />
              </div>
              <p style={{ marginLeft: "30px" }}>Picture(max 10mb) </p>
            </div>
            <ProfileUpload
              onAvatarChange={(avatar) => {
                setAvatar(avatar);
              }}
              avatar={avatar}
              type={"text"}
            />
          </div>
          <div className="info-section">
            <div className="input">
              <span className="title">Password (min 8 length)</span>
              <Input.Password
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  height: "50px",
                  border: "1px solid #3E3D4B",
                  textAlign: "left",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div className="input">
              <span className="title">Confirm Password</span>
              <Input.Password
                value={confirmPassword}
                placeholder="Confirm Password"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{
                  height: "50px",
                  border: "1px solid #3E3D4B",
                  textAlign: "left",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>
        </Input.Group>
        <div className="one-row-btns">
          <button onClick={onNext} className="profile-screen-btn">
            Next
          </button>
        </div>
      </div>
    </div>
  );

  const PasswordScreen = () => (
    <div className="profile-screen">
      <div className="img-section">
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <img src="/personal-info.svg" />
        </Space>
      </div>
      <div className="input-section">
        <Input.Group size="large" className="input-group">
        <Typography.Title level={2}>Setup Organization Profile</Typography.Title>
          <div className="pic-upload">
            <div className="image-upload">
              <div className="profile-view">
                <ProfileUpload
                  className="avatar-section"
                  onAvatarChange={(orgAvatar) => {
                    setOrgAvatar(orgAvatar);
                  }}
                  avatar={orgAvatar}
                  type={"photo"}
                />
              </div>
              <p style={{ marginLeft: "30px" }}>Logo (max 10mb) </p>
            </div>
            <ProfileUpload
              onAvatarChange={(orgAvatar) => {
                setOrgAvatar(orgAvatar);
              }}
              avatar={orgAvatar}
              type={"text"}
            />
          </div>
          <div className="info-section">
            <div className="input">
              <span className="title">Phone Number</span>
              <Input
                value={phoneNo}
                onChange={(e) => {
                  setPhoneNo(e.target.value);
                }}
                style={{
                  height: "50px",
                  border: "1px solid #3E3D4B",
                  textAlign: "left",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div className="two-row">
              <div className="input">
                <span className="title">number of employee </span>
                <Input
                  value={totalEmployee}
                  size="small"
                  type="tel"
                  onChange={(e) => setTotalEmployee(e.target.value)}
                  style={{
                    height: "50px",
                    border: "1px solid #3E3D4B",
                    textAlign: "left",
                    borderRadius: "5px",
                  }}
                />
              </div>
            </div>
            <div className="input">
              <span className="title">Address</span>
              <Input
                value={address}
                size="small"
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                style={{
                  height: "50px",
                  border: "1px solid #3E3D4B",
                  textAlign: "left",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>
        </Input.Group>
        <div className="one-row-btns">
          <button
            onClick={() => setCurrent(current - 1)}
            className="profile-screen-btn"
          >
            Previous
          </button>
          <button onClick={onNext} className="profile-screen-btn">
            Next
          </button>
        </div>
      </div>
    </div>
  );

  const FinalScreen = () => {
    return (
      <div className="welcome-screen">
        <div className="info" style={{ margin: 0 }}>
          <h2 style={{ marginTop: "135px" }}>All Done</h2>
          <p style={{ marginBottom: "48px" }}>
            You have successfully completed the set up.
          </p>
        </div>
        <img
          height="416px"
          className="welcome-image"
          src="/done-screen.svg"
          style={{ marginBottom: "64px" }}
        ></img>
        <div className="one-row-btns">
          <button
            onClick={() => setCurrent(current - 1)}
            className="profile-screen-btn"
          >
            Previous
          </button>
          <button onClick={onComplete} className="profile-screen-btn">
            Done
          </button>
        </div>
      </div>
    );
  };

  const steps = [
    {
      title: "Setup Profile",
      content: ProfileScreen(),
    },
    {
      title: "Create New Password",
      content: PasswordScreen(),
    },
    {
      title: "All Done",
      content: FinalScreen(),
    },
  ];

  return (
    <div className="OrgOnboarding">
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action"></div>
    </div>
  );
}

export default OrgOnboarding;
