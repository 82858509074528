import { createSlice } from "@reduxjs/toolkit";

export const externalNavigationSlice = createSlice({
  name: "externalNavigation",
  initialState: {
    url: null,
  },
  reducers: {
    setUrl: (state, action) => {
      state.url = action.payload;
    },
  },
});

export const {
  setUrl,
} = externalNavigationSlice.actions;

export const selectExternalUrlState = (state) => {
  return state.externalNavigation;
};

export default externalNavigationSlice.reducer;
