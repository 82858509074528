import React from 'react';
import './forgotPassword.css';
import { Input } from "antd";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from "antd";
import { forgotPwd } from "../../api/users";
import { LoadingOutlined } from "@ant-design/icons";

function validateEmail(email) {
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onVerify = async () => {
    try {
      setIsLoading(true);
      if (!validateEmail(email)) {
        message.error("Please enter valid email");
        setIsLoading(false);
        return;
      }

      await forgotPwd(email);
      message.success("Verify your email");
      navigate("/users/forgotPassword/emailConfirm");
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div className='forgot-password'>
      <img className="logo" src="/logo109.png"></img>
      <div className='forgot-password-container'>
        <div className='side-image'>
          <img src="/profile-screen.svg" />
        </div>
        <div className='reset-pwd-info'>
          <h2>Reset Password</h2>
          <p>Please fill the email address you used to register and we will send the password resent link.</p>
          <div className='reset-pwd-input'>
            <label>Email Address</label>
            <Input
              value={email}
              size="small"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
              style={{ height: "50px", border: "1px solid #3E3D4B", textAlign: "left", borderRadius: "5px", padding: "15px 16px", marginBottom: "25px" }}
            />
          </div>
          <button type='submit'onClick={onVerify} className="reset-pwd-btn">
            { isLoading? <LoadingOutlined className='btn-loading'/> : null }
            Email me the link
            </button>
          <a href='/login'>Back to login</a>
        </div>

      </div>
    </div>
  )
}

export default ForgotPassword;
