import { useState } from 'react';
import "./orgSignUp.css";
import { Input, message } from 'antd';
import { inviteOrg } from '../../api/users';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";

function validateEmail(email) {
  const res =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

const OrgSignUP = () => {
  const [adminEmail, setAdminEmail] = useState('');
  const [adminName, setAdminName] = useState('');
  const [fullOrgName, setFullOrgName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const verification = async () => {
    try {
      setIsLoading(true);
      if(!adminName || !adminName.trim().length){
        message.error("Please enter valid admin Name");
        setIsLoading(false);
        return;

      }
      if (!validateEmail(adminEmail)) {
        message.error("Please enter valid email");
        setIsLoading(false);
        return;
      }
      await inviteOrg(adminEmail, adminName, fullOrgName);
      message.success("Verify your email");
      navigate("/orgAfterSignup");
    } catch (err) {
      setIsLoading(false);
      if (err.status == 500) {
        message.warn("Organization name already exists.");
        return;
      }
    }
  }

  return (
    <div className="orgSignUp">
      <img className="logo" src="/logo109.png"></img>
      <div className="signUp-container">
        <div className="side-image">
          <img src="/login-screen.svg" />
        </div>
        <div className="form-section">
          <div className="title-section">
            <h2>Create Workspace in Roomie!</h2>
            <p>Please fill the organization information</p>
          </div>
          <div className='input-field'>
            <label>Admin Name</label>
            <Input
              value={adminName}
              size="small"
              type="text"
              onChange={(e) => setAdminName(e.target.value)}
              required={true}
            />
          </div>
          <div className='input-field'>
            <label>Admin Email</label>
            <Input
              value={adminEmail}
              size="small"
              type="email"
              onChange={(e) => setAdminEmail(e.target.value)}
              className="input-field"
              required={true}
            />
          </div>
          <div className='input-field'>
            <label>Organization Name</label>
            <Input
              value={fullOrgName}
              size="small"
              type="text"
              onChange={(e) => setFullOrgName(e.target.value)}
              required={true}
            />
          </div>
          <p className='text'>By signing up you agreed to our terms and conditions and privacy policy</p>
          <button type="submit" onClick={verification}>
            {isLoading ? <LoadingOutlined className='btn-loading' /> : null}
            Create workspace
          </button>
          <div className="donot-have-account">Already have an account?<a href='\login'> Log In</a></div>
        </div>
      </div>
    </div>
  )
}

export default OrgSignUP;
