import Search from "antd/lib/input/Search";
import { Button, Table } from "antd";
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getUsers } from "../../api/users";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";

import "./user.css";

const TableCols = [
  { title: "No", dataIndex: "no", key: "no", width: 80 },
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Email", dataIndex: "email", key: "email" },
  { title: "Team", dataIndex: "orgname", key: "orgname" },
  {
    title: "Is Verified",
    dataIndex: "isVerified",
    key: "isVerified",
    width: 100,
    render: (status) => <Checkbox defaultChecked={status} disabled />,
  },
  {
    title: "Is Active",
    dataIndex: "isActive",
    key: "isActive",
    width: 100,
    render: (status) => <Checkbox defaultChecked={status} disabled />,
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Button
        type="primary"
        size="small"
        icon={<DeleteOutlined />}
        disabled
        danger
      ></Button>
    ),
  },
  ,
];
export function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [{ count, limit }, setPagination] = useState({
    count: 0,
    limit: 0,
  });
  const [query, setQuery] = useState(null);
  const [page, setPage] = useState(1);

  const dataIndex = (page - 1) * limit;
  const [isLoading, setIsLoading] = useState(true);

  const fetchUsers = async (page, q) => {
    setIsLoading(true);
    const { data, pagination } = await getUsers(page, q);
    setUsers(data);
    setPagination(pagination);
    setIsLoading(false);
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const onSearch = (search) => {
    setQuery(search);
    setPage(1);
  };

  useEffect(() => {
    fetchUsers(page, query);
  }, [query, page]);

  return (
    <div className="User">
      <div className="header">
        <PageHeader
          onBack={() => navigate(-1)}
          title="Users"
          subTitle="Active users in workspace"
        />
        <Button
          type="primary"
          icon={<UserAddOutlined />}
          onClick={() => navigate("/users/invite")}
        >
          Invite
        </Button>
      </div>

      <div className="filter">
        <Search
          className="search"
          placeholder="Name/Email"
          onSearch={onSearch}
        />
      </div>

      <Table
        dataSource={users.map((user, index) => {
          return { no: dataIndex + index + 1, ...user, name: !user.name || !user.name.length? user.email.replace(/@.*$/,""):user.name };
        })}
        columns={TableCols}
        loading={isLoading}
        pagination={{
          size: "small",
          total: count,
          pageSize: limit,
          current: parseInt(page),
          onChange: onPageChange,
          showQuickJumper: true,
          showTotal: () => `Total ${count} users`,
        }}
      />
    </div>
  );
}
