import { createOAuthLink } from "./useOAuth";

export async function getDashboard() {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`/api/getDashboard`, {
    method: "get",
    headers,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function getTeam(orgname) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`/api/team/${orgname}`, {
    method: "get",
    headers,
  });
  if (response.status > 400) {
    throw response;
  }
  const res = await response.json();
  return res.Data;
}

export async function login(
  { email, pwd },
  { redirectUri, clientID, state, nonce }
) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");
  const url = createOAuthLink(`/api/login`, { redirectUri, clientID, state, nonce });

  const response = await fetch(url, {
    method: "post",
    headers,
    credentials: "include",
    body: JSON.stringify({ email, pwd }),
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function getOrganizations(orgnames) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`/api/organizations/getDetails`, {
    method: "post",
    headers,
    credentials: "include",
    body: JSON.stringify({ orgnames }),
  });
  if (response.status > 400) {
    throw response;
  }
  const res = await response.json();
  return res.Data;
}
