import { Button, Input, message, Space, Typography } from "antd";
import validator from "validator";
import "./override.css";
import { ProfileUpload } from "../profile/profile";
import { useEffect, useState } from "react";
import { useDashboard } from "../../api/useDashboard";
import "./onboarding.css";
import { changePassword, updateUserProfile } from "../../api/users";
import { WORKSPACE_URL } from "../../config";

export function Onboarding() {
  const { userDetails } = useDashboard();
  const [current, setCurrent] = useState(0);
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [avatar, setAvatar] = useState(null);

  async function updateUserData({
    name,
    phoneNo,
    address,
    position,
    avatar,
    isVerified,
  }) {
    await updateUserProfile({
      name,
      phoneNo,
      address,
      position,
      avatar,
      isVerified,
    });
  }

  async function onNext() {
    switch (current) {
      case 0:
        if (!name || !name.trim().length) {
          message.warning("Please enter a valid name");
          return;
        }
        if (
          !phoneNo ||
          !phoneNo.trim().length ||
          !validator.isMobilePhone(phoneNo)
        ) {
          message.warning("Please enter a valid phone number");
          return;
        }

        if (!password || !password.trim().length) {
          message.warning("Please enter a valid password");
          return;
        }
        if (password.length < 8) {
          message.warning("Minimum 8 letter password is required");
          return;
        }
        if (password !== confirmPassword) {
          message.warning("Password does not match with confirm password");
          return;
        }
        await updateUserData({ name, phoneNo, avatar });
        await changePassword({ pwd: password });
        break;
      default:
        break;
    }
    setCurrent(current + 1);
  }

  async function onComplete() {
    await updateUserData({ isVerified: true });
    window.location.href = WORKSPACE_URL
  }

  useEffect(() => {
    if (!userDetails) return;
    if (userDetails.pic) setAvatar(userDetails.pic);
    if (userDetails.name) setName(userDetails.name);
    if (userDetails.phoneNo) setPhoneNo(userDetails.phoneNo);
  }, [userDetails]);

  if (!userDetails) return <div></div>;

  const ProfileScreen = () => (
    <div className="profile-screen">
      <div className="img-section">
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <img src="/profile-setup.svg" />
        </Space>
      </div>
      <div className="input-section">
        <Input.Group size="large" className="input-group">
          <Typography.Title level={2}>Setup Your Profile</Typography.Title>
          <div className="pic-upload">
            <div className="image-upload">
              <div className="profile-view">
                <ProfileUpload
                  className="avatar-section"
                  onAvatarChange={(avatar) => {
                    setAvatar(avatar);
                  }}
                  avatar={avatar}
                  type={"photo"}
                />
              </div>
              <p style={{ marginLeft: "30px" }}>Picture(max 10mb) </p>
            </div>
            <ProfileUpload
              onAvatarChange={(avatar) => {
                setAvatar(avatar);
              }}
              avatar={avatar}
              type={"text"}
            />
          </div>
          <div className="info-section">
            <div className="input">
              <span className="title">Name</span>
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Username"
                type="text"
                style={{
                  height: "50px",
                  border: "1px solid #3E3D4B",
                  textAlign: "left",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div className="input">
              <span className="title">Phone Number </span>
              <Input
                value={phoneNo}
                size="small"
                type="tel"
                placeholder="Contact No (014378493)"
                onChange={(e) => setPhoneNo(e.target.value)}
                style={{
                  height: "50px",
                  border: "1px solid #3E3D4B",
                  textAlign: "left",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div className="info-section">
              <div className="input">
                <span className="title">Password (min 8 length)</span>
                <Input.Password
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    height: "50px",
                    border: "1px solid #3E3D4B",
                    textAlign: "left",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className="input">
                <span className="title">Confirm Password</span>
                <Input.Password
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{
                    height: "50px",
                    border: "1px solid #3E3D4B",
                    textAlign: "left",
                    borderRadius: "5px",
                  }}
                />
              </div>
            </div>
          </div>
        </Input.Group>
        <div className="one-row-btns">
          <button onClick={onNext} className="profile-screen-btn">
            Next
          </button>
        </div>
      </div>
    </div>
  );

  const FinalScreen = () => {
    return (
      <div className="welcome-screen">
        <div className="info" style={{ margin: 0 }}>
          <h2 style={{ marginTop: "135px" }}>All Done</h2>
          <p style={{ marginBottom: "48px" }}>
            You have successfully completed the set up.
          </p>
        </div>
        <img
          height="416px"
          className="welcome-image"
          src="/done-screen.svg"
          style={{ marginBottom: "64px" }}
        ></img>
        <Space>
          <Button onClick={() => setCurrent(current - 1)} type="primary">
            Previous
          </Button>
          <Button onClick={onComplete} type="primary">
            Continue to workspace
          </Button>
        </Space>
      </div>
    );
  };

  const steps = [
    {
      title: "Setup Profile",
      content: ProfileScreen(),
    },
    {
      title: "All Done",
      content: FinalScreen(),
    },
  ];

  return (
    <div className="Onboarding">
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action"></div>
    </div>
  );
}
