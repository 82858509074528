import React from 'react';
import { Button, Result } from 'antd';
import { useLocation } from 'react-router-dom';

export function ErrorPage(){
  const { search } = useLocation();
  const errorType = new URLSearchParams(search).get("type");

    const errorMessage = (()=>{
        switch(errorType){
            case "NoUserFound":
                return "You account does not exist! Please contact you admin or create new workspace."
            case "InvalidToken":
                return "Looks like your token is not valid! Please contact you admin."    
            default:
                return "Something went wrong! Please contact your admin."    
        }
    })()

    return <Result
    status="warning"
    title={errorMessage}
    extra={
      <Button type="primary" key="console" href="/login">
        Go to login
      </Button>
    }
  />
}
