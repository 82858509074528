import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDashboard, getTeam } from "./login";

export function useDashboard(redirectLogin = true) {
  const [userDetails, setUserDetails] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    async function getDashboardDetails() {
      try {
        const { userDetails } = await getDashboard();
        setUserDetails(userDetails);
      } catch (err) {
        if (err.status == 500 && redirectLogin) {
          navigate("/login");
          return;
        }
      }
    }

    getDashboardDetails();
  }, []);

  return { userDetails };
}

export function useOrgDetails(userDetails) {
  const [orgDetails, setOrgDetails] = useState(null);
  useEffect(() => {
    if(!userDetails) return;
    async function getOrgDetails() {
      const orgDetails  = await getTeam(userDetails.orgname);
      setOrgDetails(orgDetails);
    }
    getOrgDetails();
  }, [userDetails]);

  return { orgDetails };
}
