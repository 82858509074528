import React from 'react';
import { useNavigate } from 'react-router-dom';
import './forgotPasswordEmailConfirm.css';

const ForgotPasswordEmailConfirm = () => {
  const navigate = useNavigate();
  return (
    <div className='forgotPasswordEmail'>
      <img src='/logo109.png'></img>
      <h2>Forgot Password</h2>
      <p>We have sent you the details to reset the password in your email. </p>
      <button onClick={() => {
        navigate("/login");
      }}>Log In Back</button>
    </div>
  )
}

export default ForgotPasswordEmailConfirm;
