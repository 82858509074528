import { Input, Button, Upload, message, Avatar, Space, Typography } from "antd";
import { useDashboard } from "../../api/useDashboard";
import { LoadingOutlined, CameraOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getUploadUrl, updateUserProfile } from "../../api/users";
import Resizer from "react-image-file-resizer";
import { PageHeader } from "@ant-design/pro-layout";

import "./profile.css";
import { useNavigate } from "react-router-dom";

import { UserOutlined } from "@ant-design/icons";

export function ProfileUpload({ avatar, onAvatarChange, type }) {
  const [isUploading, setIsUploading] = useState(false);

  async function uploadFile(file) {
    const {
      urlOriginal,
      urlMedium,
      urlLow,
      file: { uid: fileId },
    } = await getUploadUrl({
      filename: file.name,
      mimeType: file.type,
    });

    const resizeFile = (file, height, width) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          height,
          width,
          "JPEG",
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      });

    const dataURIToBlob = (dataURI) => {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);
      return new Blob([ia], { type: mimeString });
    };

    const [mediumimage, lowimage] = await Promise.all([
      resizeFile(file, 250, 250),
      resizeFile(file, 100, 100),
    ]);

    const mediumData = dataURIToBlob(mediumimage);
    var mediumFile = new File([mediumData], file.name);

    const lowData = dataURIToBlob(lowimage);
    var lowFile = new File([lowData], file.name);

    await Promise.all([
      fetch(urlOriginal, {
        method: "PUT",
        body: file,
        headers: {
          "Cache-Control": "max-age=31536000, immutable",
        },
      }),
      fetch(urlMedium, {
        method: "PUT",
        body: mediumFile,
        headers: {
          "Cache-Control": "max-age=31536000, immutable",
        },
      }),
      fetch(urlLow, {
        method: "PUT",
        body: lowFile,
        headers: {
          "Cache-Control": "max-age=31536000, immutable",
        },
      }),
    ]);

    return fileId;
  }

  async function upload({ onProgress, onSuccess, onError, file }) {
    onProgress();
    setIsUploading(true);
    const fileID = await uploadFile(file);
    setIsUploading(false);
    onSuccess();
    onAvatarChange(window.location.origin + "/api/file/" + fileID);
  }

  return (
    <>
      {type == "photo" ? (
        <Upload
          name="avatar"
          listType="picture-circle"
          showUploadList={false}
          fileList={[]}
          customRequest={upload}
        >
          {isUploading ? (
            <Space direction="vertical">
              <LoadingOutlined className="loading-circle" />
              <Typography.Text>Uploading</Typography.Text>
            </Space>
          ) : null}

          {!isUploading && (!avatar || !avatar.trim.length) ? (
            <Avatar
              size={92}
              src={avatar + "/m"}
              icon={<UserOutlined />}
              style={{ backgroundColor: "#ede5fb", color: "#650df2" }}
            ></Avatar>
          ) : null}
        </Upload>
      ) : (
        <Upload
          listType="text"
          showUploadList={false}
          fileList={[]}
          customRequest={upload}
        >
          <a style={{ marginLeft: "20px" }}>Upload Picture</a>
        </Upload>
      )}
    </>
  );
}

export function Profile() {
  const { userDetails } = useDashboard();
  const [avatar, setAvatar] = useState(null);
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [position, setPosition] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const saveUserData = async () => {
    if (!name || !name.trim().length) {
      message.error("Please enter valid name");
      return;
    }
    if (!address || !address.trim().length) {
      message.error("Please enter valid address");
      return;
    }
    if (!position || !position.trim().length) {
      message.error("Please enter valid name");
      return;
    }
    setIsLoading(true);
    await updateUserProfile({ name, phoneNo, address, position, avatar });
    message.success("Updated user data");
    setIsLoading(false);
  };

  useEffect(() => {
    if (!userDetails) return;
    setAvatar(userDetails.pic);
    setName(userDetails.name);
    setPhoneNo(userDetails.phoneNo);
    setAddress(userDetails.address);
    setPosition(userDetails.position);
  }, [userDetails]);

  if (!userDetails) return <div></div>;

  return (
    <div className="Profile">
      <div className="header">
        <PageHeader
          onBack={() => navigate(-1)}
          title="Proifle"
          subTitle="Update your info"
        />
      </div>

      <div className="info-section">
        <div className="profile-view">
          <ProfileUpload
            className="avatar-section"
            onAvatarChange={(avatar) => {
              setAvatar(avatar);
            }}
            avatar={avatar}
            type={"photo"}
          />
        </div>

        <Input.Group size="large" className="input-group">
          <div className="input">
            <span className="title">Name: </span>
            <Input
              value={name}
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="input">
            <span className="title">Phone: </span>
            <Input
              value={phoneNo}
              placeholder="Phone"
              size="small"
              type="tel"
              onChange={(e) => setPhoneNo(e.target.value)}
            />
          </div>

          <div className="input">
            <span className="title">Address: </span>
            <Input
              value={address}
              placeholder="Address"
              size="small"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          <div className="input">
            <span className="title">Position: </span>
            <Input
              value={position}
              placeholder="Position"
              size="small"
              onChange={(e) => setPosition(e.target.value)}
            />
          </div>
        </Input.Group>
      </div>
      <Button
        type="primary"
        size="large"
        className="save-btn"
        loading={isLoading}
        onClick={saveUserData}
      >
        Save
      </Button>
    </div>
  );
}
