import { Button, Input, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDashboard, useOrgDetails } from '../../api/useDashboard';
import { updateOrganization } from '../../api/users';
import { ProfileUpload } from '../profile';
import { PageHeader } from "@ant-design/pro-layout";

import './updateOrganization.css';

// totalBranch, address, totalEmployee, orgAvatar, phoneNo, description, isVerified

const UpdateOrganization = () => {
  const { userDetails } = useDashboard();
  const {orgDetails} = useOrgDetails(userDetails);
  const [orgAvatar, setOrgAvatar] = useState(null);
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const [totalBranch, setTotalBranch] = useState("");
  const [totalEmployee, setTotalEmployee] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const saveUserData = async () => {
    if (!phoneNo || !phoneNo.trim().length || isNaN(phoneNo)) {
      message.error("Please enter valid phone number");
      return
    }
    if (!totalEmployee || !totalEmployee.trim().length ||isNaN(totalEmployee) ) {
      message.error("Please enter number of employee");
      return
    }
    if (!address || !address.trim().length) {
      message.error("Please enter organization address");
      return
    }
    setIsLoading(true);
    await updateOrganization({totalBranch, address, totalEmployee, orgAvatar, phoneNo, description })
    message.success("Updated organization details")
    setIsLoading(false);
  };

  useEffect(() => {
    if (!orgDetails) return;
    setOrgAvatar(orgDetails.pic);
    setPhoneNo(orgDetails.phoneNo);
    setTotalBranch(orgDetails.totalBranch);
    setTotalEmployee(orgDetails.totalEmployee);
    setAddress(orgDetails.address);
    setDescription(orgDetails.description);
  }, [userDetails,orgDetails]);

  if (!userDetails) return <div></div>;



  return (
    <div className="UpdateOrganization">
      <div className="header">
        <PageHeader
          onBack={() => navigate(-1)}
          title="Organization"
          subTitle="Update your organization info"
        />
      </div>
      <div className="info-section">
        <div className="profile-view">
        <ProfileUpload
                  className="avatar-section"
                  onAvatarChange={(orgAvatar) => { setOrgAvatar(orgAvatar) }}
                  avatar={orgAvatar}
                  type={"photo"}
        />
        </div>
        <Input.Group size="large" className="input-group">

          <div className="input">
            <span className="title">Phone: </span>
            <Input
              value={phoneNo}
              placeholder="Phone"
              size="small"
              type="tel"
              onChange={(e) => setPhoneNo(e.target.value)}
            />
          </div>

          <div className="input">
            <span className="title">No of Branches: </span>
            <Input
              value={totalBranch}
              placeholder="No of Branches"
              size="small"
              type="tel"
              onChange={(e) => setTotalBranch(e.target.value)}
            />
          </div>

          <div className="input">
            <span className="title">No of Employee </span>
            <Input
              value={totalEmployee}
              placeholder="No of Employee"
              size="small"
              type="tel"
              onChange={(e) => setTotalEmployee(e.target.value)}
            />
          </div>

          <div className="input">
            <span className="title">Address: </span>
            <Input
              value={address}
              placeholder="Address"
              size="small"
              type='text'
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          
          <div className="input">
            <span className="title">Description: </span>
            <Input
              value={description}
              placeholder="Write About Your Organization"
              size="small"
              type='text'
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </Input.Group>
      </div>
      <Button
        type="primary"
        size="large"
        className="save-btn"
        loading={isLoading}
        onClick={saveUserData}
      >
        Save
      </Button>
    </div>
  );
}

export default UpdateOrganization;
