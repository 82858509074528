import { Button, Input, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../api/users";
import { PageHeader } from "@ant-design/pro-layout";
import "./changePassword.css";

export function ChangePassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleChangePassword() {
    setIsLoading(true)
    if (!password || !password.trim().length) {
      message.warn("Please enter a valid password");
      return;
    }
    if (password !== confirmPassword) {
      message.warn("Password does not match with confirm password");
      return;
    }
    await changePassword({ pwd: password });
    setIsLoading(false)
    message.success("Password updated");
    navigate(-1)
  }

  return (
    <div className="ChangePassword">
      <div className="header">
        <PageHeader
          onBack={() => navigate(-1)}
          title="Change Password"
          subTitle="Change/Update your password"
        />
      </div>
      <div className="info-section">
        <Input.Group size="large" className="input-group">
          <div className="info-section">
            <div className="input">
              <Input.Password
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="input">
              <Input.Password
                value={confirmPassword}
                placeholder="Confirm Password"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
        </Input.Group>
      </div>
      <Button
        type="primary"
        size="large"
        className="save-btn"
        loading={isLoading}
        onClick={handleChangePassword}
      >
        Save
      </Button>
    </div>
  );
}
