import React from 'react';
import './forgotPasswordChange.css';
import { Input, message } from "antd";
import { useState } from 'react';
import { changeForgotPassword } from '../../api/users';
import { useLocation, useNavigate } from 'react-router-dom';


const ForgotPasswordChange = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { search } = useLocation();
  const validateToken = new URLSearchParams(search).get("code");


  const handleSubmit = async () => {
    try {
      if (!password || !password.trim().length) {
        message.warn("Please enter a valid Password");
        return;
      }
      if (password.length < 8) {
        message.warn("Minimum 8 letter password is required");
        return;
      }
      if (password !== confirmPassword) {
        message.warn("Password doesnot match");
        return;
      }
      await changeForgotPassword({ password, validateToken });
      navigate('/login');
    } catch (err) {
      if (err.Data.Code) {
        if (err.Data.name === "InvalidToken") {
          message.error("Invalid token. Please try again to reset password.");
          return
        }
      }
      message.error("Something went wrong!! Please try again later.");
    }
  }

  return (
    <div className='forgot-password'>
      <img className="logo" src="/logo109.png"></img>
      <div className='forgot-password-container'>
        <div className='side-image'>
          <img src="/profile-screen.svg" />
        </div>
        <div className='reset-pwd-info'>
          <h2>Create New  Password</h2>
          <Input.Group size="large" className="input-group">
            <div className="info-section">
              <div className="input">
                <span className="title">New Password (min 8 length)</span>
                <Input.Password
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ height: "50px", border: "1px solid #3E3D4B", textAlign: "left", borderRadius: "5px", marginBottom: "16px" }}
                />
              </div>
              <div className="input">
                <span className="title">Verify Password</span>
                <Input.Password
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{ height: "50px", border: "1px solid #3E3D4B", textAlign: "left", borderRadius: "5px" }}
                />
              </div>
            </div>
          </Input.Group>
          <button type='submit' className="reset-pwd-btn" onClick={handleSubmit}>Change</button>
          <a href='/login'>Back to login</a>
        </div>

      </div>
    </div>
  )
}

export default ForgotPasswordChange;
