import { useEffect, useState } from "react";
import { Card, Carousel, Divider, Image, message } from "antd";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import { UserOutlined, LockOutlined, TeamOutlined } from "@ant-design/icons";

import "./login.css";
import { getTeam, login } from "../../api/login";
import { useLocation, useNavigate } from "react-router-dom";
import { useDashboard } from "../../api/useDashboard";
import { useOAuth } from "../../api/useOAuth";
import {
  isDesktopApp,
  isMobileApp,
  sendMobileOpenLinkIPCSignal,
  sendOpenLinkIPCSignal,
} from "../../roomie/ipc";

export function Login() {
  const [teamName, setTeamName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { redirectUri, clientID, state, nonce } = useOAuth();
  const navigate = useNavigate();
  const { userDetails } = useDashboard(false);
  const googleLoginURL = `${window.location.origin}/api/getGoogleUrl?redirect_uri=${redirectUri}&client_id=${clientID}&state=${state}&nonce=${nonce}&orgname=${teamName}`;

  const validateTeam = async (team) => {
    setIsLoading(true);
    try {
      const { name } = await getTeam(team);
      setTeamName(name);
      setIsLoading(false);
    } catch (err) {
      if (err.status === 404) {
        message.error("No such Team / Organization");
      }
    }
    setIsLoading(false);
  };

  const onFinish = async ({ email, password }) => {
    setIsLoading(true);
    try {
      const { redirect_uri } = await login(
        { email, pwd: password },
        { redirectUri, clientID, state, nonce }
      );
      navigate(redirect_uri);
    } catch (err) {
      message.error("Email or password incorrect");
    }
    setIsLoading(false);
  };

  const handleOpenDesktopApp = () => {
    setIsLoading(true);
    sendOpenLinkIPCSignal(googleLoginURL);
  };

  const handleOpenMobileApp = () => {
    setIsLoading(true);
    sendMobileOpenLinkIPCSignal(googleLoginURL);
  };

  const handleGoogleLoginClick = () => {
    if (isDesktopApp()) {
      handleOpenDesktopApp();
      return;
    }

    if (isMobileApp()) {
      handleOpenMobileApp();
      return;
    }
    handleGoogleSignin();
  };

  const handleGoogleSignin = () => {
    window.location.href = googleLoginURL;
  };

  useEffect(() => {
    if (!userDetails) return;
    navigate("/");
  }, [userDetails]);

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <span class="relative flex h-7 w-7">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-violet-500 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-7 w-7 bg-violet-600"></span>
        </span>
        <span className="ml-4">Loading...</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center p-6 w-full h-full">
      <img className="w-20 h-20" src="/logo109.png"></img>
      <div className="flex item-center justify-center">
        <div className="">
          <div className="flex flex-col justify-center items-center">
            <h2>Welcome to Roomie!</h2>
            <p className="text-sm text-[#a1a1aa]">
              {" "}
              Please fill the information
            </p>
          </div>

          <div className="flex flex-col items-center justify-center">
            <button
              className="border rounded-lg p-2 m-3 w-full flex justify-center items-center text-base"
              onClick={handleGoogleLoginClick}
              style={{ display: "flex" }}
            >
              <img src="/google-logo.png" className="mr-2"></img> login with
              google
            </button>
            <p className="text-xs text-[#a1a1aa]">OR</p>
          </div>

          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              className="w-full mt-2"
              name="email"
              type="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <div className="input">
                <span>Work Email</span>
                <Input className="w-full mt-1 h-10" />
              </div>
            </Form.Item>
            <Form.Item
              className="w-full mt-2"
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <div className="input">
                <span>Password (min 8 length)</span>
                <Input.Password type="password" className="w-full mt-1 h-10" />
              </div>
            </Form.Item>
            <div className="flex justify-between w-full mb-8">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>
                  <div className="text text-[#a1a1aa]">Remember me</div>
                </Checkbox>
              </Form.Item>

              <a className="text-black underline" href="/users/forgotPwd">
                Forgot password
              </a>
            </div>

            <button
              type="submit"
              className="bg-black text-white w-full rounded-lg p-2"
            >
              Log in
            </button>
          </Form>
          <div className="text-sm text-[#a1a1aa] mt-2 items-center flex justify-between">
            Don't have an account?{" "}
            <a href="/orgSignUp" className="text-black underline">
              {" "}
              Create workspace
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
