import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function Logout() {
  const { search } = useLocation();
  const redirect_uri = new URLSearchParams(search).get("redirect_uri");

  async function logout() {
    await fetch("/api/logout", { method: "get" });
    window.location.href = redirect_uri
  }

  useEffect(() => {
    logout();
  }, []);

  return <div>Loading...</div>;
}
