import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../../api/useDashboard";
import { PageHeader } from "@ant-design/pro-layout";

import "./settings.css";

const { Title, Text } = Typography;

export function Settings() {
  const navigate = useNavigate();
  const { userDetails } = useDashboard();

  return (
    <div className="Settings">
      <div className="header">
        <PageHeader
          onBack={() => navigate(-1)}
          title="Settings"
          subTitle="Users settings"
        />
      </div>

      <div className="item">
        <Text>Update profile</Text>
        <Button className="btn" onClick={() => navigate("/profile")}>
          Go to profile update
        </Button>
      </div>

      <div className="item">
        <Text>Change password</Text>
        <Button className="btn" onClick={() => navigate("/change-password")}>
          Go to change password
        </Button>
      </div>
      {(userDetails && userDetails.role == "admin") ?
        <div className="item">
          <Text>Update Organization</Text>
          <Button className="btn" onClick={() => navigate("/update-organization")}>
            Go to organization update
          </Button>
        </div> : ""
      }
    </div>
  );
}
