import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useOAuth() {
  const { search } = useLocation();
  const [searchParms, setSearchParams] = useState({})

  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    const redirectUri = urlParams.get("redirect_uri");
    const clientID = urlParams.get("client_id");
    const state = urlParams.get("state");
    const nonce = urlParams.get("nonce");
    const authType = urlParams.get("authType");
    const orgnames = urlParams.getAll("orgnames");

    setSearchParams({ redirectUri, clientID, state, nonce, authType, orgnames })
  }, [search])

  return searchParms;
}

export function createOAuthLink(url, { redirectUri, clientID, state, nonce, authType, orgnames, orgname }) {
  let oAuthLink = `${url}?type=json`

  if (redirectUri) oAuthLink += `&redirect_uri=${redirectUri}`
  if (clientID) oAuthLink += `&client_id=${clientID}`
  if (state) oAuthLink += `&state=${state}`
  if (nonce) oAuthLink += `&nonce=${state}`
  if (authType) oAuthLink += `&authType=${authType}`
  if (orgname) oAuthLink += `&orgname=${orgname}`
  if (orgnames) oAuthLink += `&orgnames=${orgnames.map(o => `orgnames=${o}`).join("&")}`

  return oAuthLink
}
