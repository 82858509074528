import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Login } from "./features/login";
import { Dashboard } from "./features/dashboard";
import { Users } from "./features/users";
import { Logout } from "./features/logout";
import { Invite } from "./features/invite";
import { Profile } from "./features/profile";
import { Onboarding } from "./features/onboarding";
import { Settings } from "./features/settings";
import { ChangePassword } from "./features/changePassword";
import ForgotPasswordChange from "./features/forgotPassword/forgotPasswordChange";
import ForgotPassword from "./features/forgotPassword/forgotPassword";
import ForgotPasswordEmailConfirm from "./features/forgotPassword/forgotPasswordEmailConfirm";
import { OAuthSelectWorkspace } from "./features/oAuthSelectWorkspace";
import OrgSignUP from "./features/orgSignUp/orgSignUp";
import OrgAfterSignUp from "./features/orgSignUp/orgAfterSignUp";
import OrgOnboarding from "./features/orgOnboarding/orgOnboarding";
import UpdateOrganization from "./features/updateOrganization/updateOrganization";
import { ErrorPage } from "./features/errorsPage";
import { ConfigProvider } from "antd";
import "./css/color.css";
import { useExternalNavigation } from "./features/externalNavigation/useExternalNavigation";

function App() {
  useExternalNavigation();

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#000000",
            fontFamily: "Inter",
          },
        }}
      >
        <Routes>
          <Route path="/" element={<Dashboard />}>
            <Route path="/" element={<Users />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/invite" element={<Invite />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route
              path="/update-organization"
              element={<UpdateOrganization />}
            />
            <Route path="/settings" element={<Settings />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/orgSignUp" element={<OrgSignUP />} />
          <Route path="/orgAfterSignUp" element={<OrgAfterSignUp />} />
          <Route path="/orgOnboarding" element={<OrgOnboarding />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/users/forgotPwd" element={<ForgotPassword />} />
          <Route
            path="/oauthSelectWorkspace"
            element={<OAuthSelectWorkspace />}
          />
          <Route
            path="/forgotPasswordCallback"
            element={<ForgotPasswordChange />}
          />
          <Route
            path="/users/forgotPassword/emailConfirm"
            element={<ForgotPasswordEmailConfirm />}
          />
          <Route path="/error" element={<ErrorPage />} />
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;
